<template>
  <div>
    <calc-result-page>
      <div class="res-box">
        <div>失业保险计算结果为：</div>
        <div>
          <span class="res">{{ res.damage }}</span>
          <span class="unit">元</span>
        </div>
        <div class="explain">{{ res.desc }}</div>
      </div>

      <div class="tip">以上结果只是测算，最终以就业局计算结果为准</div>
    </calc-result-page>
  </div>
</template>

<script>
import CalcResultPage from "@/components/CalcResultPage";
export default {
  components: {
    CalcResultPage,
  },
  data() {
    return {
      res: {},
    };
  },
  mounted() {
    const res = this.$route.query.res
    if (res !== undefined) {
      this.res = JSON.parse(this.$route.query.res);
    }
  },
};
</script>

<style scoped>
.res-box {
  margin-left: 20px;
  color: #4d4d4d;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
}

.res {
  font-size: 44px;
  color: #658CF1;
}

.unit {
  font-size: 16px;
  color: #999999;
}

.explain {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #658CF1;
}

.tip {
  margin-top: 120px;
  margin-left: 40px;
  font-size: 12px;
  color: #658CF1;
}
</style>